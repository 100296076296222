import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import BannerImage from '../Assets/AwardsProject.png';
import img from '../Assets/Contact.png';
import logo from '../Assets/NOBA_NBYAA.svg';

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import BackgroundImg from '../Assets/TemplateBanner.svg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));

const Awards = () => {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '550px',
        height: '242px',
        marginTop: '141px',
        borderRadius: 0,
        boxShadow: "none",
    }
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="TemplateBanner">
            <MainContainer>
            <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={spacing}>
                            <Grid key={1} item>
                                <a href="https://nbyaa.eu/en/home/" target="_blank" rel="noopener noreferrer">
                                    <Card className={classes.root} style={ cardStyle }>
                                        <div className="ProjectLogo" style={{marginTop:'0px'}}>
                                            <img src={logo} alt="" style={{marginTop:'25px', height:"190px"}}/>
                                        </div>
                                    </Card>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>

        <div className="TemplateProjectText">
            <ProjectText>
                <h1>NOBA Awards</h1>
                <div className="ProjectTextContent">
                    <span>NOBA’s mission is to keep seeking the freshest ideas and perspectives. That means also celebrating the new creators in the region through Nordic and Baltic Young Artist Award (NOBA award) since 2016. 
                    <br /><br />
                    NOBA Award has been created to support young talents in starting their careers as professional artists and to offer both art friends and art professionals better access to discover young artists. The aim is to showcase the brightest new talents of the region and give them the best starting platform into their life as an artist through recognising their work and bringing it before an international audience. 
                    <br /><br />
                    The competition, initiated in 2016, is coordinated by NOBA and the selection process is spearheaded by an international jury. The prize connects Estonian, Latvian, Lithuanian and Finnish art academies and gathers the best works of the graduates to the online platform, promotes the graduation shows and brings international attention to the graduating students, who are starting their careers. In addition to the monetary awards and recognition, Fotografiska Tallinn chooses a winner for their award to make an exhibition in Fotografiska Tallinn.
                    <br /><br />
                    In 2019, there were more than 300 individual artworks competing for the prize, from Finland, Estonia, Latvia and Lithuania. The award gala was held in the National Art Museum in Riga. The international jury consisted of: Anne Klontz (USA/Sweden konstfack), Anna-Kaisa Rastenberger (Helsinki Uniarts), Anni Anttonen (Helsinki Uniarts), Pire Sova (Estonian Academy of Arts), Andra Orn (contemporary art platform NOAR.eu), Andris Vitolinš (Latvian Academy of Arts), Milda Dainovskytė (Vilnius Academy of Arts) Aušra Trakšelytė (Vilnius Academy of Arts). 
                    </span>
                </div>
            </ProjectText>
        </div>

        <div className="TemplateVisit">
            <VisitSiteContainer>
                <div className="VisitSite">
                    <a href="https://nbyaa.eu/en/home/" target="_blank" rel="noopener noreferrer">
                        <button>VISIT SITE</button>
                    </a>
                </div>
            </VisitSiteContainer>
        </div>

        <div className="BannerImage">
          <img src={BannerImage} alt=""/>
        </div>
        
        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default Awards;

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 525px;
    overflow: hidden;
`;

const VisitSiteContainer = styled.header`
    background: url(${img}) no-repeat center/cover;

    height: 380px;

    h1 {
        text-align: center;
        padding-top: 80px;
        font-family: 'Muli', serif;
        font-size: 32px;
        color: #231F20;
    }
    button {
        font-family: 'Muli', serif;
        font-size: 32px;
        background: #FFE786;
        padding: 24px 120px;
        border: 1px solid black;
        margin-top: 143px;
    }
    
`;

const ProjectText = styled.header`
    h1 {
        text-align: center;
        padding-top: 140px;
        padding-bottom: 140px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    span {
        text-align: center;
        padding-top: 50px;
        font-family: 'Muli', serif;
        font-weight: lighter;
        font-size: 18px;
        color: #231F20;
    }
`;