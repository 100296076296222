import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import img from '../Assets/Contact.png';
import logo from '../Assets/NOBA_Calendar.svg';

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import BackgroundImg from '../Assets/TemplateBanner.svg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));

const Calendar = () => {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '550px',
        height: '242px',
        marginTop: '141px',
        borderRadius: 0,
        boxShadow: "none",
    }
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="TemplateBanner">
            <MainContainer>
            <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={spacing}>
                            <Grid key={1} item>
                                <a href="https://noar.eu/en/exhibitions/" target="_blank" rel="noopener noreferrer">
                                    <Card className={classes.root} style={ cardStyle }>
                                        <div className="ProjectLogo" style={{marginTop:'0px'}}>
                                            <img src={logo} alt="" style={{marginTop:'55px', height:"130px"}}/>
                                        </div>
                                    </Card>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>

        <div className="TemplateProjectText">
            <ProjectText>
                <h1>NOBA Exhibition Calendar</h1>
                <div className="ProjectTextContent">
                    <span>
                    In the context of growing popularity of cultural tourism, and in keeping up with the aim to enable art-lovers to explore creative life also in their neighboring countries and in the whole region, we are bringing the information about the art events and exhibitions together into one easily navigable calendar.
                    <br /><br />
                    NOBA brings all the art events in Nordic and Baltic region together into one extensive art calendar. Explore the NOBA Exhibition calendar to see what’s being served in the region's galleries, museums and other art institutions. 
                    <br /><br />
                    Bringing together events from more than a hundred different organisers and seven different countries - Estonia, Latvia, Lithuania, Finland, Norway, Denmark and Sweden, it gives the most extensive overview of what is happening in the Nordic and Baltic art world.                    <br /><br />
                    </span>
                </div>
            </ProjectText>
        </div>

        <div className="TemplateVisit">
            <VisitSiteContainer>
                <div className="VisitSite">
                    <a href="https://noar.eu/en/exhibitions/" target="_blank" rel="noopener noreferrer">
                        <button>VISIT SITE</button>
                    </a>
                </div>
            </VisitSiteContainer>
        </div>


        
        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default Calendar;

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 525px;
    overflow: hidden;
`;

const VisitSiteContainer = styled.header`
    background: url(${img}) no-repeat center/cover;

    height: 380px;

    h1 {
        text-align: center;
        padding-top: 80px;
        font-family: 'Muli', serif;
        font-size: 32px;
        color: #231F20;
    }
    button {
        font-family: 'Muli', serif;
        font-size: 32px;
        background: #FFE786;
        padding: 24px 120px;
        border: 1px solid black;
        margin-top: 143px;
    }
    
`;

const ProjectText = styled.header`
    h1 {
        text-align: center;
        padding-top: 140px;
        padding-bottom: 140px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    span {
        text-align: center;
        padding-top: 50px;
        font-family: 'Muli', serif;
        font-weight: lighter;
        font-size: 18px;
        color: #231F20;
    }
`;