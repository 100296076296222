import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import BannerImage from '../Assets/BannerImage.png';

import Header from '../Components/Header'
import Banner from '../Components/Banner'
import BannerText from '../Components/BannerText'
import ProjectsBar from '../Components/ProjectsBar'
import Projects from '../Components/Projects'
import ContactBar from '../Components/ContactBar'
import Contacts from '../Components/Contacts'
import Footer from '../Components/Footer'

const home = () => {
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="Banner">
          <Banner />
        </div>

        <div className="BannerImage">
          <img src={BannerImage} alt=""/>
        </div>

        <div className="BannerBar">
          <BannerText />
        </div>

        <div className="ProjectsBar">
          <ProjectsBar />
        </div>

        <div className="Projects">
          <Projects />
        </div>

        <div className="ContactBar">
          <ContactBar />
        </div>

        <div className="Contacts">
          <Contacts />
        </div>

        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default home;