import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import img from '../Assets/Contact.png';
import logo from '../Assets/NOBA_Viinistu.svg';

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import BackgroundImg from '../Assets/TemplateBanner.svg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));

const Viinistu = () => {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '550px',
        height: '242px',
        marginTop: '141px',
        borderRadius: 0,
        boxShadow: "none",
    }
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="TemplateBanner">
            <MainContainer>
            <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={spacing}>
                            <Grid key={1} item>
                                    <Card className={classes.root} style={ cardStyle }>
                                        <div className="ProjectLogo" style={{marginTop:'0px'}}>
                                            <img src={logo} alt="" style={{marginTop:'55px', height:"130px"}}/>
                                        </div>
                                    </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>

        <div className="TemplateProjectText">
            <ProjectText>
              <div className="ProjectTextContent">
                <h1>NOBA at Viinistu Art Museum</h1>
                <h3 style={{paddingTop:'0px'}}>Viinistu Art Harbour</h3>
                    <span>
                    On the Estonian northern coast, deep into the Gulf of Finland, where the ancient Viking trade routes began their journey to the East, lies a rugged green peninsula. Filled to the brim with rivers, lakes, and wild old forests, teeming with deer, boar and wolves that habit one of the most diverse natural forest reserves in Europe. 
                    <br /><br />
                    Its coastline, ringed with desolate beaches where the cries of seagulls rhyme with the lapping of the icy cold waves, is also home to one of the most impressive private museums in the region, Viinistu Art Museum, created by Jaan Manitski. Born in the very same village, he escaped by boat with his family to Sweden, where he grew up and worked as a business manager for legendary band ABBA. After returning to Estonia he served as a Foreign Minister and moved back to his birthplace, establishing the Viinistu Art Museum. 
                    <br /><br />
                    With more than 1000 different artworks from hundreds of artists, Viinistu Art Museum has been the favourite destination with art- and nature-lovers alike, coming by land or by sea. NOBA has teamed up with Viinistu Art Harbour to help develop it as a contemporary art hub for artists and art-lovers alike.
                    </span>
                    <br /><br /><br />
                <h3>Viinistu Art Residency</h3>
                    <span>
                    Our collaboration with the museum has brought into life a perfect getaway for creative minds - a live-in residency for artists where the wild nature and the slow idyllic pace of a tiny ancient fishing village create a perfect incubator for the birth of new ideas and collaborations. 
                    </span>
                    <br /><br /><br />
                <h3>Viinistu Art Museum</h3>
                    <span>
                    Taking advantage of the heritage buildings and structures, the expansive museum inhabits an old cold storage of a fish processing plant, giving ample space for the artworks coming from the Baltic-German era and Pallas school, up to modern times.
                    </span>
                    <br /><br /><br />
                <h3>Barrel galleries</h3>
                    <span>
                    The co-joining Barrel galleries, situated in old water tanks, showcase new contemporary exhibitions each month. NOBA helps to curate the collection and run the Viinistu Art Residency, while also curating and organising the exhibitions in the Barrel galleries themselves. 
                    </span>
                    <br /><br /><br />
                <h3>Artist House Museum</h3>
                    <span>
                    Future projects involve an Artist House Museum project, whose first artist in focus will be Estonia's most famous printmaker Peeter Allik. Recreating his studio and showcasing his works in an intimate setting will give a unique chance to experience the art as well as the artist in a way that usual gallery exhibitions rarely allow.                    </span>
              </div>
            </ProjectText>
        </div>
        
        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default Viinistu;

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 525px;
    overflow: hidden;
`;

const ProjectText = styled.header`
    h1 {
        text-align: center;
        padding-top: 140px;
        padding-bottom: 140px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    h3{
        text-align: center;
        padding-top: 20px;
        padding-bottom: 50px;
        font-family: 'Lora', serif;
        font-weight: regular;
        font-size: 24px;
        color: #231F20;
    }

    span {
        text-align: center;
        padding-top: 50px;
        font-family: 'Muli', serif;
        font-weight: lighter;
        font-size: 18px;
        color: #231F20;
    }
`;