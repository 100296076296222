import React from 'react'
import Grid from '@material-ui/core/Grid';
import BannerLogo from '../Assets/NOBAlogo.png';

const Banner = () => {
    return (
        <div className="BannerGrid">
            <Grid container className="BannerContainer">
                <Grid item xs={12}>
                <Grid container justify="center">
                    <Grid key={1} item style={{marginRight: '50px'}}>
                    <img src={BannerLogo} alt="NOBA Logo"/>
                    </Grid>
                    <Grid key={2} item>
                    <h1>Nordic & Baltic</h1>
                    <h1>Art Center</h1>
                    </Grid>
                </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default Banner
