import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import img from '../Assets/Contact.png';
import logo from '../Assets/NOBA_Spotlight.svg';

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import BackgroundImg from '../Assets/TemplateBanner.svg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));

const Spotlight = () => {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '550px',
        height: '242px',
        marginTop: '141px',
        borderRadius: 0,
        boxShadow: "none",
    }
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="TemplateBanner">
            <MainContainer>
            <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={spacing}>
                            <Grid key={1} item>
                                <a href="https://noar.eu/en/spotlight/" target="_blank" rel="noopener noreferrer">
                                    <Card className={classes.root} style={ cardStyle }>
                                        <div className="ProjectLogo" style={{marginTop:'0px'}}>
                                            <img src={logo} alt="" style={{marginTop:'55px', height:"142.5px"}}/>
                                        </div>
                                    </Card>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>

        <div className="TemplateProjectText">
            <ProjectText>
                <h1>NOBA Spotlight</h1>
                <div className="ProjectTextContent">
                    <span>
                    Art is created by people to people, between the creator and the audience there are many people helping to bring ideas and concepts to life. Through Spotlight, we want to offer an authentic peek into the everyday life of an artist, the inner workings of a creative process and into the birth of an artwork. 
                    <br /><br />
                    Diving into the diverse and colourful world of creative people and entities, Spotlight continuously hunts for the intriguing characters and stories from the whole region. Conducting interviews with artists, probing into the inner workings of the art institutions and presenting in depth the most exciting upcoming events - the NOBA Spotlight is the best way to keep yourself updated with the latest developments of the Nordic and Baltic art scene. 
                    <br /><br />
                    For example, artists Marko Mäetamm, Jaan Toomik, Andris Vitolins and many others, have opened up about their creative process, ideas and latest inspirations. Director of the Helsinki Art Museum, Maija Tanninen-Mattila; curator of Tallinn Architecture Biennial, Dr. Yael Reisner, amongst others have shared with Spotlight their vision and perspective regarding the future of their disciples. 
                    <br /><br />
                    NOBA is constantly screening the rich art calendar to find the events that need to be highlighted through more in-depth stories in the Spotlight. Our contributors are covering the most impactful, as well as more underground events to provide recommendations on what not to miss - Oslo Biennale, Momentum 10, Art Vilnius and many other events are brought to you from across the region, as they happen.
                    <br /><br />
                    NOBA works together with writers from all Nordic and Baltic countries to provide content that is timely, authentic and engaging, reflecting the multitude of perceptions that exist around us. In case you wish to contribute to NOBA Spotlight, please don't hesitate to contact us. We are always welcoming new writers who are passionate about discovering the latest art in its many forms. 
                    </span>
                </div>
            </ProjectText>
        </div>

        <div className="TemplateVisit">
            <VisitSiteContainer>
                <div className="VisitSite">
                    <a href="https://noar.eu/en/spotlight/" target="_blank" rel="noopener noreferrer">
                        <button>VISIT SITE</button>
                    </a>
                </div>
            </VisitSiteContainer>
        </div>


        
        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default Spotlight;

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 525px;
    overflow: hidden;
`;

const VisitSiteContainer = styled.header`
    background: url(${img}) no-repeat center/cover;

    height: 380px;

    h1 {
        text-align: center;
        padding-top: 80px;
        font-family: 'Muli', serif;
        font-size: 32px;
        color: #231F20;
    }
    button {
        font-family: 'Muli', serif;
        font-size: 32px;
        background: #FFE786;
        padding: 24px 120px;
        border: 1px solid black;
        margin-top: 143px;
    }
    
`;

const ProjectText = styled.header`
    h1 {
        text-align: center;
        padding-top: 140px;
        padding-bottom: 140px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    span {
        text-align: center;
        padding-top: 50px;
        font-family: 'Muli', serif;
        font-weight: lighter;
        font-size: 18px;
        color: #231F20;
    }
`;