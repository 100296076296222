import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Andra from '../Assets/AndraOrn.png';
import phone_icon from '../Assets/phone.svg';
import email_icon from '../Assets/email.svg';
import FB_icon from '../Assets/FB.svg';
import Insta_icon from '../Assets/Insta.svg';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
    },
  }));

function Contacts() {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        height: '100%',
        minHeight: '223px',
        width: '223px',
        textAlign: 'center',
        fontFamily: 'Lora',
        borderRadius: 0,
        boxShadow: "none",
        top: '50%',
    }

    return (
        <div className="ContactsSection">
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={spacing}>
                <Grid key={1} item>
                    <Card className={classes.root} style={ cardStyle }>
                    <CardContent style={{marginTop:'5%',}}>
                        <Typography variant="h4" component="h4" style={{marginTop:'30px',fontSize:'20px', fontWeight:'600',fontFamily:'Lora', color:'#231F20'}}>
                            Andra Orn
                        </Typography>
                        <Typography variant="h5" component="h5" style={{marginBottom:'20px', fontSize:'18px',fontFamily:'Lora', color:'#231F20'}}>
                            Project Manager
                        </Typography>
                        <Typography variant="body2" component="p" style={{marginBottom:'5px',fontFamily:'Muli', fontSize:'14px', color:'#231F20'}}>
                            <a href="tel:+372 53 300 122"><img src={phone_icon} alt="" style={{height:'18px',}}/> +372 53 300 122 </a>
                        </Typography>
                        <Typography variant="body2" component="p" style={{fontFamily:'Muli', fontSize:'14px', color:'#231F20'}}>
                            <a href="mailto:andra@nobaac.com"> <img src={email_icon} alt="" style={{height:'16px',}}/> andra@nobaac.com </a>
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid key={2} item>
                    <Card className={classes.root} style={ cardStyle }>
                        <img src={Andra} alt="Profiil"/>
                    </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
            
          <Grid container className={classes.root} spacing={2} style={{marginTop:'30px'}}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={spacing}>
                <Grid key={1} item>
                    <Card className={classes.root} style={ cardStyle }>
                    <CardContent style={{marginTop:'5%',}}>
                        <Typography variant="h5" component="h4" style={{marginTop:'30px',fontSize:'20px', fontWeight:'600',fontFamily:'Lora', color:'#231F20'}}>
                            Connect With Us
                        </Typography>
                        <Typography variant="h6" component="h5" style={{marginBottom:'20px', fontSize:'18px',fontFamily:'Lora', color:'#231F20'}}>
                            Follow us at:
                        </Typography>
                        <Typography variant="body2" component="p" style={{marginTop:'30px',fontFamily:'Lora', color:'#231F20'}}>
                        <a href="https://www.facebook.com/NOARkunst" target="_blank" rel="noopener noreferrer">
                            <img src={FB_icon} alt=""  style={{marginRight:'12px', height:'40px',}}/>
                        </a>
                        <a href="https://www.instagram.com/noarart/" target="_blank" rel="noopener noreferrer">
                            <img src={Insta_icon} alt="" style={{marginLeft:'12px', height:'40px',}}/>
                        </a>
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </div>
    )
}

export default Contacts
