import React from 'react'
import styled from 'styled-components';
import img from '../Assets/Contact.png';

const ContactBar = () => {
    return (
        <MainContainer>
            <h1>Contact</h1>
            <h3>Please do get in touch - we’d love to hear from you.</h3>
        </MainContainer>
    )
}

export default ContactBar;

//Incorrect height till text is added
const MainContainer = styled.header`
    background: url(${img}) no-repeat center/cover;

    height: 380px;

    h1 {
        text-align: center;
        padding-top: 100px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    h3 {
        text-align: center;
        padding-top: 80px;
        font-family: 'Muli', serif;
        font-size: 24px;
        color: #231F20;
    }
`;