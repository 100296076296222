import React from 'react'
import styled from 'styled-components';

const BannerText = () => {
    return (
        <MainContainer>
            <h1><span style={{color : '#FFE786'}}>Empowering Creators.</span> Connecting Art & People.</h1>
        </MainContainer>
    )
}

export default BannerText;

//Main container
const MainContainer = styled.header`
    background-color: #333333;
    padding-top: 30px;
    padding-bottom: 30px;

    h1 {
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 1px;
        color: white;
        font-family: 'Lora', serif;
        margin-bottom: 0px;
    }
`;