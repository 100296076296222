import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import BannerImage from '../Assets/TAWProject.png';
import img from '../Assets/Contact.png';
import logo from '../Assets/TAWFullLogo.svg';

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import BackgroundImg from '../Assets/TemplateBanner.svg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));

const TAW = () => {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '550px',
        height: '242px',
        marginTop: '141px',
        borderRadius: 0,
        boxShadow: "none",
    }
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="TemplateBanner">
            <MainContainer>
            <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={spacing}>
                            <Grid key={1} item>
                                <a href="https://www.tallinnartweek.ee/en/index.html" target="_blank" rel="noopener noreferrer">
                                    <Card className={classes.root} style={ cardStyle }>
                                        <div className="ProjectLogo" style={{marginTop:'0px'}}>
                                            <img src={logo} alt="" style={{marginTop:'-10px', height:"260px"}}/>
                                        </div>
                                    </Card>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>

        <div className="TemplateProjectText">
            <ProjectText>
                <h1>Tallinn Art Week</h1>
                <div className="ProjectTextContent">
                    <span>Art Week is an intensive week-long art festival that brings together the freshest harvest from the local art scene. The aim of the festival is to present the latest works of the local artists and help break barriers around art and artists.
                    <br /><br />
                    We see art as a 'daily bread' that should be essential and accessible to every human being. Therefore we are bringing the artists and artworks out from their usual hangouts and galleries, onto the city streets, public spaces and other unexpected areas that people visit every day. To give people a chance to live for a week, face to face with the art that has been created around them, inspired by the topics, questions and realities we live in.
                    <br /><br />
                    Expect the unexpected, be ready for strange ideas, experimental forms, odd spaces and raw emotion. Creativity and bold vision merge into a week of art explosion that shakes your senses and opens up new perspectives. Live performances, exhibitions, installations and other experimental forms of art take over the public spaces, both inside and outside, all around the city.
                    <br /><br />
                    Art Week has been hosted in Tallinn, three times, since 2016, and we are looking to expand this well-established festival format to Tartu and other cities in Estonia and beyond. We are inviting collaborations with local governments and art institutions to bring this festival to your country, your city.
                    </span>
                </div>
            </ProjectText>
        </div>

        <div className="TemplateVisit">
            <VisitSiteContainer>
                <div className="VisitSite">
                    <a href="https://www.tallinnartweek.ee/en/index.html" target="_blank" rel="noopener noreferrer">
                        <button>VISIT SITE</button>
                    </a>
                </div>
            </VisitSiteContainer>
        </div>

        <div className="BannerImage">
          <img src={BannerImage} alt=""/>
        </div>
        
        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default TAW;

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 525px;
    overflow: hidden;
`;

const VisitSiteContainer = styled.header`
    background: url(${img}) no-repeat center/cover;

    height: 380px;

    h1 {
        text-align: center;
        padding-top: 80px;
        font-family: 'Muli', serif;
        font-size: 32px;
        color: #231F20;
    }
    button {
        font-family: 'Muli', serif;
        font-size: 32px;
        background: #FFE786;
        padding: 24px 120px;
        border: 1px solid black;
        margin-top: 143px;
    }
    
`;

const ProjectText = styled.header`
    h1 {
        text-align: center;
        padding-top: 140px;
        padding-bottom: 140px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    span {
        text-align: center;
        padding-top: 50px;
        font-family: 'Muli', serif;
        font-weight: lighter;
        font-size: 18px;
        color: #231F20;
    }
`;