import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import BannerImage from '../Assets/BiennalProject.png';
import img from '../Assets/Contact.png';
import logo from '../Assets/TallinnBiennial.svg';

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import BackgroundImg from '../Assets/TemplateBanner.svg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));

const Biennal = () => {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '550px',
        height: '242px',
        marginTop: '141px',
        borderRadius: 0,
        boxShadow: "none",
    }
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="TemplateBanner">
            <MainContainer>
            <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={spacing}>
                            <Grid key={1} item>
                                <a href="https://tallinnbiennial.com/" target="_blank" rel="noopener noreferrer">
                                    <Card className={classes.root} style={ cardStyle }>
                                        <div className="ProjectLogo" style={{marginTop:'0px'}}>
                                            <img src={logo} alt="" style={{marginTop:'-10px', height:"262px"}}/>
                                        </div>
                                    </Card>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>

        <div className="TemplateProjectText">
            <ProjectText>
                <h1>Tallinn Art Biennial 2020</h1>
                <div className="ProjectTextContent">
                    <span>Tallinn Art Biennial is a chance to witness and experience the freshest works of contemporary art from across the Nordic and Baltic region. The aim is to offer a more underground look into the creative scene, where the need to experiment and express prevails over the need to be right or perfect. 
                    <br /><br />
                    Welcoming all the undercurrents, that get shunned by the prim mainstream, this is where you find the views freed from the constraints of convention, the edgiest and most unpolished raw breath of real air. You get to witness the dialogue between the artist and the art itself, where being certain means failure. TAB urges you to keep on asking, exploring and feeling by accepting the unpolished, strange and unexpected realities of life.
                    <br /><br />
                    Tallinn Art Biennial has grown out organically from Tallinn Art Week and is taking place for the first time in 2020 as a pilot. Staying small, curious and experimental, it is covering topics and themes that resonate across borders to offer a truly authentic view to the international art audience about the realities of current times from a number of Baltic and Nordic artists.
                    </span>
                </div>
            </ProjectText>
        </div>

        <div className="TemplateVisit">
            <VisitSiteContainer>
                <div className="VisitSite">
                    <a href="https://tallinnbiennial.com/" target="_blank" rel="noopener noreferrer">
                        <button>VISIT SITE</button>
                    </a>
                </div>
            </VisitSiteContainer>
        </div>

        <div className="BannerImage">
          <img src={BannerImage} alt=""/>
        </div>
        
        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default Biennal;

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 525px;
    overflow: hidden;
`;

const VisitSiteContainer = styled.header`
    background: url(${img}) no-repeat center/cover;

    height: 380px;

    h1 {
        text-align: center;
        padding-top: 80px;
        font-family: 'Muli', serif;
        font-size: 32px;
        color: #231F20;
    }
    button {
        font-family: 'Muli', serif;
        font-size: 32px;
        background: #FFE786;
        padding: 24px 120px;
        border: 1px solid black;
        margin-top: 143px;
    }
    
`;

const ProjectText = styled.header`
    h1 {
        text-align: center;
        padding-top: 140px;
        padding-bottom: 140px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    span {
        text-align: center;
        padding-top: 50px;
        font-family: 'Muli', serif;
        font-weight: lighter;
        font-size: 18px;
        color: #231F20;
    }
`;