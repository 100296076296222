import React from 'react'
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import BackgroundImg from '../Assets/Projects.png';
import ProjectBackground from '../Assets/ProjectButton.svg';
import Biennial from '../Assets/TallinnBiennial.svg';
import TAW from '../Assets/TAW.svg';
import Awards from '../Assets/NOBA_NBYAA.svg';
import Calendar from '../Assets/NOBA_Calendar.svg';
import Viinistu from '../Assets/NOBA_Viinistu.svg';
import Spotlight from '../Assets/NOBA_Spotlight.svg';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));
  

function Projects() {
    const classes = useStyles();
    var cardStyle = {
        background: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '205px',
        height: '205px',
        borderRadius: 0,
        boxShadow: "none",
    }

    return (
        <div className="ProjectsSection">
            <MainContainer>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                    <Grid container justify="center" spacing={0} style={{marginTop:'90px'}}>
                        <Grid key={1} item>
                            <Nav.Link as={NavLink} to="/Biennal">
                                <Card className={classes.root} style={ cardStyle }>
                                    <div className="ProjectLogo">
                                        <img className="ProjectLogoBackground" src={ProjectBackground} alt=""/>
                                        <img className="ProjectLogoImage" src={Biennial} alt=""/>
                                    </div>
                                </Card>
                            </Nav.Link>
                        </Grid>
                        <Grid key={2} item>
                            <Nav.Link as={NavLink} to="/TAW">
                                <Card className={classes.root} style={ cardStyle }>
                                    <div className="ProjectLogo">
                                        <img className="ProjectLogoBackground" src={ProjectBackground} alt=""/>
                                        <img className="ProjectLogoImage" src={TAW} alt="" style={{width:'205px', height:'160px', marginTop:'-10px',}}/>
                                    </div>
                                </Card>
                            </Nav.Link>
                        </Grid>
                        <Grid key={3} item>
                            <Nav.Link as={NavLink} to="/Awards">
                                <Card className={classes.root} style={ cardStyle }>
                                    <div className="ProjectLogo">
                                        <img className="ProjectLogoBackground" src={ProjectBackground} alt=""/>
                                        <img className="ProjectLogoImage" src={Awards} alt="" style={{width:'195px', marginTop:'20px', marginLeft:"5px"}}/>
                                    </div>
                                </Card>
                            </Nav.Link>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>

                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                    <Grid container justify="center" spacing={0} style={{marginTop:'55px'}}>
                        <Grid key={1} item>
                            <Nav.Link as={NavLink} to="/Calendar">
                                <Card className={classes.root} style={ cardStyle }>
                                    <div className="ProjectLogo">
                                        <img className="ProjectLogoBackground" src={ProjectBackground} alt=""/>
                                        <img className="ProjectLogoImage" src={Calendar} alt="" style={{height:'85px', marginTop:'25px'}}/>
                                    </div>
                                </Card>
                            </Nav.Link>
                        </Grid>
                        <Grid key={2} item>
                            <Nav.Link as={NavLink} to="/Viinistu">
                                <Card className={classes.root} style={ cardStyle }>
                                    <div className="ProjectLogo">
                                        <img className="ProjectLogoBackground" src={ProjectBackground} alt=""/>
                                        <img className="ProjectLogoImage" src={Viinistu} alt="" style={{height:'80px', marginTop:'25px'}}/>
                                    </div>
                                </Card>
                            </Nav.Link>
                        </Grid>
                        <Grid key={3} item>
                            <Nav.Link as={NavLink} to="/Spotlight">
                                <Card className={classes.root} style={ cardStyle }>
                                    <div className="ProjectLogo">
                                        <img className="ProjectLogoBackground" src={ProjectBackground} alt=""/>
                                        <img className="ProjectLogoImage" src={Spotlight} alt="" style={{height:'90px', marginTop:'25px'}}/>
                                    </div>
                                </Card>
                            </Nav.Link>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>
    )
}

export default Projects

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 705px;
`;