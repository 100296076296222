import React from 'react'
import styled from 'styled-components';

function ProjectsBar() {
    return (
        <MainContainer>
            <h1>Our Projects</h1>
            <h3>We design meeting points for humans and art. By bringing them together through our events and virtual spaces, we invite people across the world to connect to the freshest ideas and perspectives born in the Nordic and Baltic region.</h3>
        </MainContainer>
    )
}

export default ProjectsBar

//Incorrect height till text is added
const MainContainer = styled.header`
    background-color: #F2F2F2;

    height: 445px;

    h1 {
        text-align: center;
        padding-top: 100px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    h3 {
        text-align: center;
        margin-left: 15%;
        margin-right: 15%;
        padding-top: 75px;
        font-family: 'Muli', serif;
        font-size: 18px;
        color: #231F20;
        line-height: 1.5;
        margin-bottom: 75px;
    }
`;