import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import '../App.scss';
import img from '../Assets/Contact.png';
import logo from '../Assets/NOBAlogo.svg';

import Header from '../Components/Header'
import Footer from '../Components/Footer'

import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import Contacts from '../Components/Contacts'

import BackgroundImg from '../Assets/TemplateBanner.svg';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
      },
  }));

const Calendar = () => {
    const [spacing] = React.useState(4);
    const classes = useStyles();
    var cardStyle = {
        backgroundColor: 'white',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Lora',
        width: '550px',
        height: '242px',
        marginTop: '141px',
        borderRadius: 0,
        boxShadow: "none",
    }
  return (

    <div className="App">
    
        <div className="Header"> 
          <Header />
        </div>
    
        <div className="TemplateBanner">
            <MainContainer>
            <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={spacing}>
                            <Grid key={1} item>
                                <a href="/" >
                                    <Card className={classes.root} style={ cardStyle }>
                                        <div className="ProjectLogo" style={{marginTop:'0px'}}>
                                            <img src={logo} alt="" style={{marginTop:'79px', height:"80px"}}/>
                                        </div>
                                    </Card>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </div>

        <div className="TemplateProjectText">
            <ProjectText>
                <h1>MTÜ Nordic Baltic Art Center NOBA</h1>
                <h3><i>Empowering creators. Connecting art and people. </i></h3>
                <div className="ProjectTextContent">
                    <span>
                    Humankind started making art long before it knew how to write. It is the universal language that connects us all. In times like these, we need that common language more than ever before.
                    <br /><br />
                    Nordic Baltic Art Centre (NOBA) carries a mission to empower art as a medium and bring forth the fruits of the Nordic-Baltic contemporary art scene. We believe that art is like air. We need it to stay alive. And we want it fresh.
                    <br /><br />
                    NOBA aims to bring together the creators of the Nordic-Baltic contemporary art world. This means constantly working together with artists and institutions to co-create and bring forth the whole creative power of the region. Two of the best places to witness that are at Tallinn Biennial or during Tallinn Art Week, which present the freshest selection from the Nordic and Baltic art scenes. 
                    <br /><br />
                    Part of NOBA’s mission is to keep seeking the freshest ideas and perspectives. That means also celebrating the new creators in the region through Nordic and Baltic Young Artist Award (NOBA award) since 2016.
                    <br /><br />
                    We always aim to embrace sustainability as a core value in everything we do, both through addressing the vast topic through the art itself, at the events we curate and organise, bringing it forth in the discussions at our seminars and workshops, as well as in choosing how we organise our technical work around the exhibitions, performances and the art residency.
                    <br /><br />
                    Connecting art and people is at the heart of our mission. Too much art is enjoyed only by small numbers of people and too many people don’t dare to step into a gallery at all. We want to break down these walls and open up the landscape for more people to be connected to art as a medium; to connect to the perspectives and ideas around them through art. 
                    <br /><br />
                    By working together with art institutions and artists, NOBA brings to you the smorgasbord of the most relevant and boundary-pushing creative works of the region every day. Explore our calendar to see what’s being served in the region now and see the Spotlight section to discover the latest developments and who are the most intriguing people making it happen. 
                    <br /><br />
                    We are always open to new ideas and collaborations, so don’t hesitate to seek us out.
                    </span>
                </div>
            </ProjectText>
        </div>

        <div className="Contacts">
          <Contacts />
        </div>
        
        <div className="Footer">
          <Footer />
        </div>

    </div>
  );
}

export default Calendar;

const MainContainer = styled.header`
    background: url(${BackgroundImg}) no-repeat center/cover;
    height: 525px;
    overflow: hidden;
`;

const ProjectText = styled.header`
    h1 {
        text-align: center;
        padding-top: 140px;
        padding-bottom: 140px;
        font-family: 'Lora', serif;
        font-weight: bold;
        font-size: 42px;
        color: #231F20;
    }
    h3{
        text-align: center;
        padding-bottom: 50px;
        font-family: 'Lora', serif;
        font-weight: regular;
        font-size: 24px;
        color: #231F20;
    }

    span {
        text-align: center;
        padding-top: 50px;
        font-family: 'Muli', serif;
        font-weight: lighter;
        font-size: 18px;
        color: #231F20;
    }
`;