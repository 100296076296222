import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollIntoView from "./Components/ScrollIntoView";
 
import Home from './pages/Home';
import TAW from './pages/TAW';
import Biennal from './pages/Biennal';
import Awards from './pages/Awards';
import Calendar from './pages/Calendar';
import Spotlight from './pages/Spotlight';
import Viinistu from './pages/Viinistu';
import About from './pages/About';
 
class App extends Component {
  render() {
    return (      
       <BrowserRouter>
        <div>
          <ScrollIntoView>
            <Switch>
              <Route path="/" component={Home} exact/>
              <Route path="/TAW" component={TAW}/>
              <Route path="/Biennal" component={Biennal}/>
              <Route path="/Awards" component={Awards}/>
              <Route path="/Calendar" component={Calendar}/>
              <Route path="/Spotlight" component={Spotlight}/>
              <Route path="/Viinistu" component={Viinistu}/>
              <Route path="/About" component={About}/>
            </Switch>
          </ScrollIntoView>
        </div> 
      </BrowserRouter>
    );
  }
}
 
export default App;