import React from 'react';
import styled from 'styled-components';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../Assets/HeaderLogo.svg';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <MainContainer>
            <div className="HeaderSection">
                <Navbar color="dark" className="navbar-dark navbar-static-top" bg="black">
                    <Navbar.Brand href="/"><img src={Logo} alt=""/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-header"></Navbar.Toggle>
                    <Navbar.Collapse id="navbar-header">
                        <Nav className="mr-auto">
                            <Nav.Link as={NavLink} to="/TAW">ART WEEK</Nav.Link>
                            <Nav.Link as={NavLink} to="/Biennal">BIENNIAL</Nav.Link>
                            <Nav.Link as={NavLink} to="/Awards">AWARDS</Nav.Link>
                            <Nav.Link as={NavLink} to="/Calendar">CALENDAR</Nav.Link>
                            <Nav.Link as={NavLink} to="/Spotlight">SPOTLIGHT</Nav.Link>
                            <Nav.Link as={NavLink} to="/Viinistu">VIINISTU</Nav.Link>
                            <Nav.Link as={NavLink} to="/About">ABOUT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </MainContainer>
    )
}

export default Header;

//Main container
const MainContainer = styled.header`
    background: black;
    color: white;
`;

