import React from 'react'
import styled from 'styled-components';

function Footer() {
    return (
        <MainContainer>
            <span>MTÜ Nordic and Baltic Contemporary Art Center NOAR | Pärnu mnt 154, Tallinn 11317 | <a href="tel:+372 53 300 122">+372 53 300 122</a> | <a href="mailto:info@nobaac.com">info@nobaac.com</a></span>
        </MainContainer>
    )
}

export default Footer

//Main container
const MainContainer = styled.header`
    background: black;
    left: 0;
    bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    span {
        font-size: 13px;
        color: white;
        font-family: 'Muli', sans-serif;
    }
`;